<template>
  <Modal
    :ui="{ root: { size: 'max-w-218', addition: 'max-lg:p-4.5 max-lg:rounded-b-0 flex gap-8' }, wrapper: { addition: 'max-lg:justify-center max-lg:items-end max-lg:pb-0' } }"
    @close="emits('closeModal')"
  >
    
<FormKitLazyProvider config-file="true" :default-config="false">
<div class="flex-1">
      <h2
        class="flex justify-between items-center text-tekstovyy text-5 lg:text-7 font-700 relative overflow-hidden max-lg:pt-4.5 max-lg:-mt-4.5"
      >
        {{ `Добавить \n адрес доставки` }}
        <img
          class="size-18 max-lg:absolute bottom-0 right-0"
          src="/imgs/back-call2.png"
          alt=""
        >
      </h2>
      <p class="text-seryy text-3.25 lg:text-3.5 leading-1.3 mt-4">
        Благодаря точному адресу, мы сможем рассчитать
        среднее время доставки и сумму заказа
      </p>
      <FormKit
        type="form"
        form-class="grid grid-cols-2 gap-y-4 lg:gap-y-6 gap-x-4 mt-4 lg:mt-6"
        :actions="false"
        :incomplete-message="false"
        :config="formkitConfig"
        @submit="submitHandler"
      >
        <FormKit
          name="fias_id"
          validation="required:trim"
          :floating-label="true"
          label="Адрес доставки (улица, дом)"
          type="autocomplete"
          outer-class="col-span-full relative"
          :options="getAddress"
          :dynamic="{ enabled: true }"
          dropdown-wrapper-class="absolute top-[calc(100%+.25rem)] left-0 w-full bg-plashka-green rounded-3 overflow-hidden z-1 shadow-[0_6px_18px_0] shadow-black/16"
          list-box-class="max-h-75 overflow-auto custom-scroll w-full"
          list-item-class="cursor-pointer my-5 mx-8 rounded-3 text-tekstovyy text-4"
          status-label-class="block my-4 mx-8 rounded-3 text-tekstovyy text-4"
          @input="changeCoords!"
        />
        <FormKit
          name="flat"
          validation="required:trim"
          :floating-label="true"
          label="Кв./офис"
          type="text"
        />
        <FormKit
          name="doorphone"
          :floating-label="true"
          label="Домофон"
          type="text"
        />
        <FormKit
          name="entrance"
          :floating-label="true"
          label="Подъезд"
          type="text"
        />
        <FormKit
          name="floor"
          :floating-label="true"
          label="Этаж"
          type="text"
        />

        <FormKit
          name="comment"
          :floating-label="true"
          label="Комментарий курьеру"
          type="text"
          outer-class="col-span-full relative"
        />
        <FormKit
          name="city_id"
          type="meta"
          :value="1"
        />
        <Button class="col-span-full max-lg:mt-2">Добавить</Button>
      </FormKit>
    </div>
    <div
      id="mapContainer2gis"
      ref="mapContainer"
      class="flex-1.2 max-lg:fixed max-lg:top-0 max-lg:inset-0 max-lg:-z-1 [&_button]:leading-0"
    ></div>
</FormKitLazyProvider>

    <!-- <div class="
      flex-1.2
      max-lg:fixed max-lg:top-0 max-lg:inset-0 max-lg:-z-1"
    >
      <yandex-map
        :settings="{
          location: {
            //@ts-ignore
            center: coordsRef,
            zoom: 11,
          },
        }"
        width="100%"
        height="100%"
      >
        <YandexMapMarker
          :position="'top left-center'"
          :settings="{
            //@ts-ignore
            coordinates: coordsRef,
            mapFollowsOnDrag: true,
            id: 'DefaultMark'
          }"
        >
          <div class="w-13.25 h-18.5">
            <img
              src="/imgs/map-pin.svg"
              alt=""
            >
          </div>
        </YandexMapMarker>

        <yandex-map-default-scheme-layer />
        <yandex-map-default-features-layer />
      </yandex-map>
      </div> -->
  </Modal>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { YandexMap, YandexMapDefaultSchemeLayer, YandexMapDefaultFeaturesLayer, YandexMapMarker } from 'vue-yandex-maps';
import { useGeo } from '~/composables/geo/useGeo';
import { useUserInfo } from '~/composables/user/useUserInfo';
import type { UserAddressReqType } from '~/composables/user/types/UserTypes';
import { useUserAuth } from '~/composables/user/useUserAuth';
import type { AddressFormInitType } from '~/types/commons';
import type { GeoType } from '~/composables/geo/types/GeoType';

import { load } from '@2gis/mapgl';

const emits = defineEmits(['closeModal']);

const { $toast } = useNuxtApp();
const { getUserMe } = useUserAuth();
const { getGeoList } = useGeo();
const { userAddAddress, userAddCurrentAddress } = useUserInfo();

const { isLoggedIn } = useUserAuth();

const formkitConfig = {
  labelClass: '$reset text-4.25 absolute !left-4.5 mb-0 !bg-transparent group-data-[complete]:top-0 group-data-[complete]:translate-y-2.25 group-data-[complete]:text-3 group-data-[complete]:text-firmennyy/60 group-data-[invalid]:!text-#ED6A41 text-firmennyy/50 group-focus-within:top-0 group-focus-within:translate-y-2.25 group-focus-within:text-3 group-focus-within:text-firmennyy/60',
  messagesClass: 'absolute top-full left-0 w-full',
  outerClass: 'relative',
  inputClass: 'w-inherit mb-2 mt-6 [&[data-expanded=true]~label]:top-0 [&[data-expanded=true]~label]:translate-y-2.25 [&[data-expanded=true]~label]:text-3 [&[data-expanded=true]~label]:text-firmennyy/60',
  innerClass: '$remove:py-2.75 $remove:px-3.5 px-4.5 relative bg-plashka-green $remove:border-border border-plashka-green group-data-[invalid]:bg-#ED6A41/10 group-data-[invalid]:border-#ED6A41/50'
};

const mapContainer = ref(null);
// @ts-ignore
let map;

const initMap = async () => {
  await nextTick(() => {
    setTimeout(() => {
      load().then((mapglAPI) => {
        // @ts-ignore
        map = new mapglAPI.Map(mapContainer.value, {
          center: coordsRef.value,
          zoom: 13,
          key: '65a6d130-891f-4d83-b1fa-edcb5f9eeff8'
        });
        // @ts-ignore

        let marker = new mapglAPI.Marker(map, {
          coordinates: coordsRef.value
        });
        watch(coordsRef, (newCoords) => {
          if (marker) {
            // @ts-ignore
            marker.destroy();
          }
          // @ts-ignore
          marker = new mapglAPI.Marker(map, {
            coordinates: newCoords
          });
          // @ts-ignore
          map.setCenter(newCoords);
        });
      });
    }, 200);
  });
};

onMounted(() => {
  initMap();
});

onBeforeUnmount(() => {
  console.log(document.querySelector('#mapContainer2gis'), 'UNMOUNT');
  // @ts-ignore
  if (map) {
    map.destroy();
  }
});

const coordsRef = ref([142.736, 46.9541]);

async function submitHandler (form: AddressFormInitType) {
  // @ts-ignore
  const addressForm: UserAddressReqType = { ...form, address: form.fias_id.label, fias_id: null, lon: coordsReactive.lon, lat: coordsReactive.lat };
  if (isLoggedIn.value) {
    const res = await userAddAddress(addressForm).catch((err) => {
      console.log(err.data.message);
      return false;
    });

    if (res) {
      await getUserMe({ isNeedUpdate: true }).then((res) => {
        if (res?.addresses) {
          userAddCurrentAddress(res?.addresses[res?.addresses.length - 1]);
          localStorage.setItem('address', JSON.stringify(res?.addresses[res?.addresses.length - 1]));
        }
      }).catch((err) => {
        console.log(err.data.message);
      });
    }
  }

  $toast.success('Адрес успешно добавлен');
  // userAddCurrentAddress(form);
  emits('closeModal');
}

const coordsReactive = reactive({
  lon: '',
  lat: ''
});

function changeCoords (address:GeoType) {
  console.log(address);
  coordsRef.value = [Number(address.lon), Number(address.lat)];
  coordsReactive.lat = address.lat;
  coordsReactive.lon = address.lon;
}

async function getAddress (search: string) {
  if (!search || search.length < 5) { return []; }
  const res = await getGeoList({ search: `г.Южно-Сахалинск, ${search}` })
    .catch((err) => {
      console.log(err.data.message);
      $toast.error(err.data.message);
      return null;
    });
  if (!res) { return []; }
  console.log(res);
  return res.map((result) => ({
    label: result.value,
    value: {
      label: result.value,
      value: result.fias_id,
      lat: result.lat,
      lon: result.lon
    }
  }));
}

</script>
