import type { GeoType, GeoTypeReq } from '~/composables/geo/types/GeoType';
import type { FetchError } from 'ofetch';
import type { ServerResponseCommonType } from '~/types/commons';

type UseGeoType = {
	getGeoList: (options:GeoTypeReq) => Promise<GeoType[]>;
}

export function useGeo ():UseGeoType {
  const { $api } = useNuxtApp();

  async function getGeoList (options:GeoTypeReq):Promise<GeoType[]> {
    const res = await $api<ServerResponseCommonType<GeoType[]>>('geo/getList', {
      query: { ...options }
    }).catch((err:FetchError) => {
	    throw err;
    });

    if (res.data && res.data.length) {
      return res.data;
    }

    return [];
  }

  return {
	  getGeoList
  };
}
